<template>
  <div class="view pa24">
    <el-button type="primary" @click="add_tap">新增海报模块</el-button>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column align="center" type="index" width="50" label="序号"> </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="海报模块标题"
          show-overflow-tooltip
        />
        <el-table-column prop="images" align="center" label="图标 ">
        <template slot-scope="scope">
          <el-image
                  style="width: 36px;height:36px"
                  :src="scope.row.logo"
                  :preview-src-list="scope.row.imagesList">
          </el-image>
        </template>
        </el-table-column>
        <el-table-column prop="note" align="center" label="图片数量" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="detall_tap(scope.row,1)">编辑</el-button>
            <el-button type="text" @click="detall_tap(scope.row,0)">详情</el-button>
            <el-button type="text" @click="delete_tap(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  queryPagePoster,
  deletePoster
} from "@/api/addedServices";
export default {
  data() {
    return {
      tableData:[],
      loading:'',
      currentPage: 1, //当前页数
      total: 0, //总条数
      pageNum:1,
      pageSize:10,
    };
  },
  components: {
    commonTable,
  },
  created(){
    this.getTableData()
  },
  methods:{
    async getTableData() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize:this.pageSize,
      };
      const result = await queryPagePoster(data);
      this.loading = false
      if(result.data){
        this.tableData = result.data.pageInfo.list;
        this.total = result.data.pageInfo.total
        this.tableData.forEach(v=>{
          v.imagesList = [];
          v.imagesList.push(v.logo);
        })
      }else{
        this.tableData = []
      }
    },
    add_tap(){
      this.$router.push('/posterAtlasDetall')
    },
    detall_tap(row,type){
      this.$router.push({ path:`/posterAtlasDetall?classificationId=${(row == 1 ? '' :  row.classificationId)}&type=${type}` });
    },
    edit_tap(){

    },
    delete_tap(row){
      this.$confirm("确定删除此海报模块?", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        deletePoster(row)
        .then(res=>{
          if(res.code == 200){
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.getTableData()
          }
        })
        
      })
      .catch((err) => {});
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
  }
};
</script>

<style lang="scss" scoped>
</style>